<template>
	<router-view style="padding:12px"/>
</template>

<script>
	export default {
		components: {},
		data() {
			return {};
		},
		watch: {},
		methods: {},
	};
</script>

<style lang='scss' scoped>
	.content {
		margin: 12px;
	}

	.el-menu-item-group .el-menu-item-group__title {
		padding: 0 !important;
	}

	.outerMenu.el-menu-item-group .el-menu-item-group__title {
		padding: 0 !important;
	}
</style>